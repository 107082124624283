.gallery {
    .gallery-tabs {
        padding: 0;
        @media (max-width: 1100px) {
            padding-left: $padding-small;
            padding-right: $padding-small;
        }
    }
    .react-tabs {
        &__tab {
            &-list {
                border-color: $tertiary-color;
            }
            &--selected {
                background: $tertiary-color;
                color: $white;
                font-weight: 400;
                letter-spacing: 1px;
            }
        }
        &__tab-list {
            li {
                width: 50%;
            }
        }
    }
}
