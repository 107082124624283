/* Radio buttons */
.field-container {
    &.radio-button-group {
        legend {
            font-family: GothamBook;
            font-size: $regular-font-size;
            line-height: 20px;
            letter-spacing: -0.5px;
        }
        .is-invalid {
            label {
                border-color: $radio-error !important;
                background: $radio-error;
                &:before {
                    border-color: $radio-error-before !important;
                    background: $radio-error-before !important;
                }
                .radio__icon--dog,
                .radio__icon--cat,
                .radio__icon--rabbit {
                    width: 76px;
                    height: 76px;
                    // background-color: $icon-bg-selected;
                    // border-color: $icon-bg-selected;
                    position: relative;
                    .overlay {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 76px;
                        height: 76px;
                        border-radius: 50%;
                        background: $radio-icon-error;
                    }
                }
            }
        }
    }
    .radio {
        display: inline-block;
        @extend %margin-right;
        @extend %margin-bottom;

        input[type='radio'] {
            position: absolute;
            opacity: 0;
            &:not(:checked) {
                ~ label:hover {
                    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
                }
            }
            &:focus {
                ~ label {
                    background: $radio-checked-hover;
                    &:before {
                        border-color: $radio-checked;
                    }
                }
            }
            + .radio__label {
                width: 100%;
                height: $input-field-height;
                border-radius: $border-radius;
                border: 1px solid $border-grey;
                cursor: pointer;
                margin-top: -1px;
                padding: 0 18px 0 0;
                color: $primaryGrey;
                transition: all $transition-speed ease;
                line-height: $input-field-height;
                font-family: EagleBook;
                font-size: $regular-font-size;
                letter-spacing: -0.5px;
                &.is-invalid {
                    border-color: $radio-error;
                    background: $radio-error;
                    &:before {
                        border-color: $radio-error-before;
                        background: $radio-error-before;
                    }
                }
                &:before {
                    content: '';
                    background: $radio-unchecked;
                    border-radius: 100%;
                    border: 1px solid $border-grey;
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    margin: 9px 10px;
                    position: relative;
                    vertical-align: top;
                    cursor: pointer;
                    text-align: center;
                    transition: all 700ms ease;
                }
            }

            &:checked {
                ~ .radio__label {
                    // border-color: $input-valid;
                    border: none;
                    background: $input-valid;
                    &:before {
                        border-color: $input-valid-dark;
                        border-width: 12px;
                    }
                }
            }

            &:disabled {
                + .radio__label {
                    &:before {
                        box-shadow: inset 0 0 0 4px $radio-unchecked;
                        border-color: darken($radio-unchecked, 25%);
                        background: darken($radio-unchecked, 25%);
                    }
                }
            }

            + .radio-label {
                &:empty {
                    &:before {
                        margin-right: 0;
                    }
                }
            }
            &.input-error + label,
            &.input-error + label:before {
                border-color: $error-state;
                border-left-width: 6px;
            }
        }

        &--image {
            .radio__icon--dog {
                background-image: url('$defaultDogIcon');
            }

            .radio__icon--cat {
                background-image: url('$defaultCatIcon');
            }

            .radio__icon--dog,
            .radio__icon--cat,
            .radio__icon--rabbit {
                background-size: 82%;
                background-position: center 10px;
                background-repeat: no-repeat;
                width: 76px;
                height: 76px;
                background-color: $icon-bg-grey;
                border-radius: 50%;
            }
            input[type='radio'] {
                &:checked {
                    ~ label {
                        border: 1px solid transparent;
                        background: $input-valid;
                        .radio__icon--dog,
                        .radio__icon--cat,
                        .radio__icon--rabbit {
                            width: 76px;
                            height: 76px;
                            position: relative;
                            .overlay {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 76px;
                                height: 76px;
                                border-radius: 50%;
                                background: $input-valid-half;
                            }
                        }
                    }
                }
            }

            label {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 125px;
                padding: 0 11px;
                border: 1px solid $border-grey;
                color: $primaryGrey;
                font-size: 16px;
                text-align: center;
                border-radius: $border-radius;
                cursor: pointer;
                width: 100px;
                .radio__icon-text {
                    font-family: EagleBook;
                    font-size: $regular-font-size;
                    letter-spacing: -0.48px;
                }
            }
        }

        &.radio--sub-text {
            @include bp-prop(
                (
                    md: 58px,
                ),
                'height'
            );
        }

        &--sub-text {
            input[type='radio'] {
                & + .radio__label {
                    @include bp-prop(
                        (
                            md: 58px,
                        ),
                        'height'
                    );
                    @include bp-prop(
                        (
                            default: inherit,
                            md: normal,
                        ),
                        'line-height'
                    );
                }
            }
        }

        &--text-bottom {
            input[type='radio'] {
                + .radio__label {
                    padding: 20px 0 12px;
                    text-align: center;

                    &:before {
                        margin: 0;
                    }
                }
            }

            .radio__payment-text {
                display: block;
                margin-top: 5px;
                text-align: center;
            }
        }

        &__fixed-size {
            width: auto !important;
        }
    }
    .pet-radio {
        height: auto !important;
        width: 100px;
        max-width: 260px !important;
        margin-right: 10px;
        margin-bottom: 0;
        .radio__label {
            padding: $padding-small !important;
            height: auto !important;

            &:before {
                content: none !important;
            }
            &.label__pet-chooser {
                line-height: 16px !important;
                font-size: 14px;
                text-align: center;
                img {
                    width: 76px;
                    height: 76px;
                    border-radius: 50%;
                }
                .pet-name {
                    font-family: EagleBook;
                    font-size: $regular-font-size;
                    line-height: 16px;
                    letter-spacing: -0.48px;
                    @extend %margin-top;
                    @extend %margin-bottom;
                }
            }
        }
        input[type='radio']:checked ~ .radio__label:not(.radio__icon--dog) {
            color: $primaryGrey;
            background: $input-valid;
            img {
                opacity: 0.6;
            }
        }
    }
}
