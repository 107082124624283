@import './breakpoints';

@mixin bp-prop($map-values, $property) {
    @each $bp, $value in $map-values {
        @if $bp == 'default' {
            #{$property}: $value;
        }
        @if $bp != 'default' {
            $min-width: map-get($breakpoints-min, $bp);

            @media screen and (min-width: $min-width+'px') {
                #{$property}: $value;
            }
        }
    }
}

@mixin outlineShadow {
    box-shadow: 0 0 0 4px $focus-outline-color;
}

// @mixin boldFont($m-font-size: $regular-font-size, $d-font-size: null, $colour: null) {
//     font-family: $boldFont;
//     font-weight: normal;
//     font-style: normal;
//     font-stretch: normal;
//     @if $d-font-size == null {
//         font-size: $m-font-size !important;
//     } @else {
//         @include bp-prop(
//             (
//                 default: $m-font-size,
//                 lg: $d-font-size,
//             ),
//             'font-size'
//         );
//     }
//     $colour: if($colour == null, $primaryGrey, $colour);
//     color: $colour;
// }

@mixin setIconImage($image-name) {
    background-image: url('/img/brand/#{$brandName}/svg-icons/menu/#{$image-name}.svg');
}

@mixin radioHoverStates {
    &:not(:checked) {
        ~ label:hover {
            border: 1px solid rgb(60, 60, 60);
            color: $radio-label-hover;
            background: rgb(245, 245, 245);

            &:before {
                transition: all $transition-speed ease;
                border-color: rgb(60, 60, 60);
                border-width: 2px;
            }
        }
    }

    &:checked {
        ~ label {
            &:hover {
                border-color: $input-hover-border-checked;
                color: $radio-label-hover;

                &:before {
                    color: $input-hover-border-checked;
                    background-color: $input-hover-border-checked;
                    transition: all $transition-speed ease;
                    border-color: $input-hover-border-checked;
                }
            }
        }
    }

    &:focus {
        ~ label {
            box-shadow: $input-shadow-focus;
            background: $radio-checked-hover;
            &:before {
                border-color: $radio-checked;
            }
        }
    }
}
