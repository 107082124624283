footer.main-content-container {
    @media (max-width: 768px) {
        margin-bottom: 60px;
    }

    .inner-content {
        display: flex;
        justify-content: center;
    }
}
