.read-more-link {
    text-decoration: underline;
    @extend %link;
    white-space: nowrap;
    &.dark {
        color: $primaryGrey;
        &:hover {
            color: $secondary-grey;
        }
    }
    &.light {
        color: $white;
        &:hover {
            color: #f1f1f1;
        }
    }
}
