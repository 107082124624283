.contact-block {
    background: #ddd;
    &__tay-avatar {
        background: $tay-avatar;
        border-radius: 50%;
        border: 1px solid $border-grey;
        height: 60px;
        width: 60px;
        margin-right: $padding-small;
    }
    &__copy-cta {
        display: flex;
        flex-basis: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
        &__copy {
            h4,
            p {
                margin-bottom: 0;
            }
            p {
                font-size: $smallFont;
            }
        }
        &__container {
            display: flex;
            align-items: center;
            @media (max-width: 576px) {
                flex-basis: 100%;
                //margin-bottom: $padding-small;
            }
        }
    }
}
