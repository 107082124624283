/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 12px;
    top: 12px;
    display: none;
    @media (min-width: 768px) {
        display: none;
    }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: $white;
    border-radius: 2px;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
    background: $white;
    padding: 20px;
    img {
        margin-bottom: 20px;
        width: 80%;
    }
    ul.header-side-nav {
        outline: none;
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;
        margin-left: -20px;
        & > li {
            @extend %margin-right;
            margin-bottom: $padding-small;
            a {
                @extend %sidenav;
                padding-left: 16px;
                display: flex;
                align-items: center;
                border-left: 4px solid transparent;
                color: $secondary-grey;
                height: 40px;
                &[aria-current='true'],
                &:hover {
                    border-left: 4px solid $secondary-color;
                    text-decoration: none;
                    color: $primary-color;
                }
            }
        }
    }
    ul.sub-links {
        outline: none;
        list-style: none;
        margin-bottom: 0;
        padding-left: 20px;
        & > li {
            @extend %margin-right;

            a {
                @extend %body-copy;
                padding-left: 16px;
                display: flex;
                align-items: center;
                border-left: 2px solid #ccc;
                color: $secondary-grey;
                height: 40px;
                &[aria-current='true'],
                &:hover {
                    border-left: 2px solid $primary-color;
                    text-decoration: none;
                    color: $primary-color;
                }
            }
        }
    }
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}
