.icon-social {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: $icon-bg-grey;
    display: inline-flex;
    align-items: center;
    margin-right: $padding-small;
    justify-content: center;
    img {
        max-width: 24px;
    }
}
