/* buttons */
.btn-primary {
    //@include boldFont(18px, $colour: $white);
    letter-spacing: 0.5px;
    background-color: $primary-cta;
    border-color: $primary-cta;
    height: $cta-button-height;
    border-radius: $border-radius;
    text-align: center;
    padding-left: 16px;
    padding-right: 16px;
    vertical-align: baseline;
    line-height: 32px;
    @media (max-width: 768px) {
        width: 100%;
    }
    &:hover,
    &:focus,
    &:active {
        color: $white;
        background-color: $primary-cta-hover;
        border-color: $primary-cta-hover;
    }

    &.hollow {
        background: $white;
        color: $primary-cta;
        border: 2px solid $primary-cta;

        &:hover,
        &:focus,
        &:active {
            border-color: $primary-cta-hover;
            background-color: $secondary-cta-hover;
        }
        &:disabled {
            color: $primary-cta-disabled;
            border-color: $primary-cta-disabled;
        }
    }

    &.success {
        background-color: $success;
        border-color: $success;
        &:hover,
        &:focus,
        &:active {
            border-color: darken($success, 20%) !important;
            background-color: darken($success, 20%) !important;
        }
    }
    &.info {
        background-color: $info;
        border-color: $info;
        &:hover,
        &:focus,
        &:active {
            border-color: darken($info, 20%) !important;
            background-color: darken($info, 20%) !important;
        }
    }
    &.warning {
        background-color: $warning;
        border-color: $warning;
        color: $primaryGrey;
        &:hover,
        &:focus,
        &:active {
            border-color: darken($warning, 20%) !important;
            background-color: darken($warning, 20%) !important;
        }
    }
    &.error {
        background-color: $error;
        border-color: $error;
        &:hover,
        &:focus,
        &:active {
            border-color: darken($error, 20%) !important;
            background-color: darken($error, 20%) !important;
        }
    }
}

.btn:not(.link-button):not(.accordion-item) {
    margin-bottom: $outer-padding-mobile;

    &:hover,
    &:focus,
    &:active {
        border-color: $primary-cta-hover;
    }
}
