$margin: 8px;
.gallery-images {
    @extend %margin-bottom;
    padding: 0 !important;
    img {
        width: 100%;
        margin-bottom: $margin;
        border-radius: $border-radius;
    }
    .my-masonry-grid {
        display: -webkit-box; /* Not needed if autoprefixing */
        display: -ms-flexbox; /* Not needed if autoprefixing */
        display: flex;
        margin-left: -$margin; /* gutter size offset */
        width: auto;
        @extend %margin-bottom;
    }
    .my-masonry-grid_column {
        padding-left: $margin; /* gutter size */
        background-clip: padding-box;
    }

    /* Style your items */
    .my-masonry-grid_column > div {
        /* change div to reference your elements you put in <Masonry> */
        background: grey;
        margin-bottom: $margin;
    }
}
