body {
    color: $white;
    overflow-y: scroll;
    background: $black;
}
html {
    background: $black;
}
.main-content-container {
    width: 100%;
    background: #000000;
    .inner-content {
        @extend %padding;
        position: relative;
        &.library {
            max-width: 960px;
        }
        &.feefo {
            max-width: $site-max-width;
        }
        width: 100%;
        @include media-breakpoint-up(sm) {
            max-width: $site-max-width;
        }

        margin: auto;
    }
}

.site-footer {
    > .inner {
        max-width: $site-max-width;
        margin: auto;
    }
}
.no-left-padding {
    padding-left: 0 !important;
}

.no-right-padding {
    padding-right: 0 !important;
}

/* Responsive */
%padding {
    @include bp-prop(
        (
            default: $outer-padding-mobile,
            md: $outer-padding,
        ),
        'padding'
    );
}

%padding-left {
    @include bp-prop(
        (
            default: $outer-padding-mobile,
            md: $outer-padding,
        ),
        'padding-left'
    );
}

%padding-bottom {
    @include bp-prop(
        (
            default: $outer-padding-mobile,
            md: $outer-padding,
        ),
        'padding-bottom'
    );
}

%padding-top {
    @include bp-prop(
        (
            default: $outer-padding-mobile,
            md: $outer-padding,
        ),
        'padding-top'
    );
}

.padding-right,
%padding-right {
    @include bp-prop(
        (
            default: $outer-padding-mobile,
            md: $outer-padding,
        ),
        'padding-right'
    );
}

%margin {
    @include bp-prop(
        (
            default: $outer-padding-mobile,
            md: $outer-padding,
        ),
        'margin'
    );
}

%margin-right {
    @include bp-prop(
        (
            default: $outer-padding-mobile,
            md: $outer-padding,
        ),
        'margin-right'
    );
}

%margin-left {
    @include bp-prop(
        (
            default: $outer-padding-mobile,
            md: $outer-padding,
        ),
        'margin-left'
    );
}

%margin-bottom {
    @include bp-prop(
        (
            default: $outer-padding-mobile,
            md: $outer-padding,
        ),
        'margin-bottom'
    );
}

%margin-top {
    @include bp-prop(
        (
            default: $outer-padding-mobile,
            md: $outer-padding,
        ),
        'margin-top'
    );
}

%margin-top-none {
    @include bp-prop(
        (
            default: none,
            md: none,
        ),
        'margin-top'
    );
}

.cta-link {
    @extend %body-copy-small;
    border-radius: 6px;
    padding: 10px;
    background: $primary-color;
    height: 30px;
    display: flex;
    align-items: center;
    color: $white;
}

p {
    @extend %body-copy;
}

%secondary-border {
    border-left: 10px solid $secondary-color;
    //border-radius: 6px 0 0 6px;
}

%vertical-center {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

#pageLoadingCoverAnimation {
    position: absolute;
    z-index: 10;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    @extend %h1;
    color: $white;
    justify-content: center;
    align-items: center;
    &.hide {
        display: none;
    }
    &.show {
        display: flex;
    }
}

.react-tabs {
    &__tab {
        color: lighten($primaryGrey, 60%);
        &:hover {
            color: $primaryGrey;
        }
        &--selected {
            border: none;
            color: $primaryGrey;
            font-weight: bold;
            @extend %tab-title;
        }
    }
    &__tab-list {
        margin-bottom: 0;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #dbdbdb;
        li {
            height: 50px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
        }
    }
    &__tab-panel {
        padding-bottom: 0;
        @extend %padding-top;
        overflow: auto;
        h4 {
            @extend %margin-bottom;
            border-left: 4px solid $primary-color;
            padding: 8px;
            background: #f6f6f6;
        }
        p {
            img {
                border-radius: $border-radius;
                @extend %margin-bottom;
                width: 40%;
                &.left {
                    float: left;
                    @extend %margin-right;
                }
                &.right {
                    float: right;
                    @extend %margin-left;
                }
                @media (max-width: 768px) {
                    width: 100%;
                    margin-right: 0;
                }
            }
        }
    }
}

$maxImageWidth: 34%;
$maxImageWidthTablet: 60%;

.copy-content {
    p {
        img {
            border-radius: $border-radius;
            &.main {
                max-width: 100%;
            }

            &.left,
            &.right {
                max-width: $maxImageWidth;
                @extend %margin-bottom;
                @media (max-width: 768px) {
                    max-width: $maxImageWidthTablet;
                    @media (max-width: 667px) {
                        max-width: 100%;
                    }
                }
            }
            &.left {
                float: left;
                display: block;
                @extend %margin-right;
            }
            &.right {
                float: right;
                @extend %margin-left;
            }
        }
    }
}
