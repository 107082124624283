@font-face {
    font-family: 'InterBold';
    src: local('InterBold'), url(../fonts/Inter-Bold.woff) format('woff');
}

@font-face {
    font-family: 'InterBoldItalic';
    src: local('InterBoldItalic'),
        url(../fonts/Inter-BoldItalic.woff) format('woff');
}

@font-face {
    font-family: 'InterRegular';
    src: local('InterRegular'), url(../fonts/Inter-Regular.woff) format('woff');
}

@font-face {
    font-family: 'InterItalic';
    src: local('InterItalic'), url(../fonts/Inter-Italic.woff) format('woff');
}

@font-face {
    font-family: 'InterLight';
    src: local('InterLight'), url(../fonts/Inter-Light.woff) format('woff');
}

@font-face {
    font-family: 'InterLightItalic';
    src: local('InterLightItalic'),
        url(../fonts/Inter-LightItalic.woff) format('woff');
}

@font-face {
    font-family: 'InterMedium';
    src: local('InterMedium'), url(../fonts/Inter-Medium.woff) format('woff');
}

@font-face {
    font-family: 'InterMediumItalic';
    src: local('InterMediumItalic'),
        url(../fonts/Inter-MediumItalic.woff) format('woff');
}

@font-face {
    font-family: 'InterExtraBold';
    src: local('InterExtraBold'),
        url(../fonts/Inter-ExtraBold.woff) format('woff');
}

$headerFont: 'InterExtraBold', sans-serif !default;
$boldFont: plantin, sans-serif !default;
$font: 'InterRegular', sans-serif !default;
$mediumFont: 'InterMedium', sans-serif !default;
$normalFont: 'InterRegular', sans-serif !default;
$lightFont: 'InterLight', sans-serif !default;

$xlFont: 24px;
$titleFont: 20px;
$regularFont: 16px;
$smallFont: 12px;
$tinyFont: 8px;
$impactMetric: 56px;

@for $i from 6 through 40 {
    %lineHeight#{$i} {
        line-height: #{$i}px;
    }
}

%tab-title,
.tab-title {
    font-family: $boldFont;
    font-size: $titleFont;
    @extend %lineHeight20;
    letter-spacing: -0.45px;
}

%load-more,
%article-bold,
.load-more {
    font-family: $boldFont;
    font-size: $titleFont;
    @extend %lineHeight20;
    letter-spacing: -0.45px;
}

%sidenav,
.sidenav {
    font-family: $boldFont;
    font-size: $titleFont;
    @extend %lineHeight20;
    letter-spacing: -0.88px;
}

%body-copy,
.body-copy {
    font-family: $font;
    font-size: $regularFont;
    @extend %lineHeight22;
    letter-spacing: -0.48px;
}

%body-copy-small,
.body-copy-small {
    font-family: $font;
    font-size: $smallFont;
    @extend %lineHeight14;
    letter-spacing: -0.36px;
}

%body-heading,
.body-heading {
    font-family: $boldFont;
    font-size: $regularFont;
    @extend %lineHeight21;
    letter-spacing: -0.48px;
}

%link,
.link {
    font-family: $headerFont;
    font-size: $regularFont;
    @extend %lineHeight18;
    letter-spacing: -0.48px;
}

%strap,
.strap {
    font-family: $normalFont;
    font-size: $regularFont;
    @extend %lineHeight20;
    letter-spacing: -0.48px;
}

/* Text colors */
$primaryGrey: #333;
$secondaryGrey: #666;
$inputPlaceholderFocus: #258ba7;
$disabledGrey: #999;
$outlineGrey: #ddd;
$backgroundGrey: #eee;
$coloredBg: #fff;

/* Font sizing */
$tiny-font-size: 10px;
$regular-font-size: 16px;

$h1Key: 'h1';
$h2Key: 'h2';
$h3Key: 'h3';
$h4Key: 'h4';

$heading: (
    'font': (
        'mobile': (
            'fontSize': (
                $h1Key: 36px,
                $h2Key: 28px,
                $h3Key: 20px,
                $h4Key: 16px,
            ),
            'lineHeight': (
                $h1Key: 36px,
                $h2Key: 28px,
                $h3Key: 20px,
                $h4Key: 16px,
            ),
            'letterSpacing': (
                $h1Key: 1px,
                $h2Key: 0.7px,
                $h3Key: 0.88x,
                $h4Key: 0px,
            ),
        ),
        'desktop': (
            'fontSize': (
                $h1Key: 48px,
                $h2Key: 36px,
                $h3Key: 28px,
                $h4Key: 20px,
            ),
            'lineHeight': (
                $h1Key: 56px,
                $h2Key: 36px,
                $h3Key: 28px,
                $h4Key: 20px,
            ),
            'letterSpacing': (
                $h1Key: 1px,
                $h2Key: 0.7px,
                $h3Key: 0.88x,
                $h4Key: 0px,
            ),
        ),
    ),
);

@for $i from 1 through 4 {
    h#{$i},
    .h#{$i},
    %h#{$i} {
        font-family: plantin, sans-serif;
        font-weight: 600;
        font-size: map-get(
            map-get(map-get(map-get($heading, 'font'), 'mobile'), 'fontSize'),
            'h#{$i}'
        );
        letter-spacing: map-get(
            map-get(
                map-get(map-get($heading, 'font'), 'mobile'),
                'letterSpacing'
            ),
            'h#{$i}'
        );
        line-height: map-get(
            map-get(map-get(map-get($heading, 'font'), 'mobile'), 'lineHeight'),
            'h#{$i}'
        );
        @include media-breakpoint-up(md) {
            font-family: plantin, sans-serif;
            font-weight: 600;
            font-size: map-get(
                map-get(
                    map-get(map-get($heading, 'font'), 'desktop'),
                    'fontSize'
                ),
                'h#{$i}'
            );
            letter-spacing: map-get(
                map-get(
                    map-get(map-get($heading, 'font'), 'desktop'),
                    'letterSpacing'
                ),
                'h#{$i}'
            );
            line-height: map-get(
                map-get(
                    map-get(map-get($heading, 'font'), 'desktop'),
                    'lineHeight'
                ),
                'h#{$i}'
            );
        }
    }
}
