.page-strap {
    background: $secondary-color;
    color: $white;
    @extend %margin-bottom;
    @extend %margin-top;
    .inner-content {
        &.center {
            text-align: center;
        }
        .blurb {
            width: 100%;
            p {
                margin-bottom: 0;
            }

            &.homepage {
                max-width: 690px;
                margin: 0 auto;
            }
            .services-container {
                display: flex;
                flex-wrap: wrap;
                @extend %padding-top;
                a {
                    color: $white;
                    display: inline-flex;
                    flex-basis: 100%;
                    justify-content: center;
                    @extend %margin-bottom;
                    @media (min-width: 768px) {
                        flex-basis: unset;
                        margin-right: 10px;
                        margin-bottom: 0;
                    }
                    .service-link {
                        display: flex;
                        flex-direction: column;
                        img {
                            max-height: 60px;
                        }
                    }
                }
            }
        }
    }
}
