.field-container {
    .form-option {
        &--checkbox {
            @extend %margin-bottom;
            position: relative;
            align-items: center;
            display: flex;
            min-height: $input-field-height;
            &.is-invalid {
                label {
                    background: $input-invalid;
                    border-color: $input-invalid !important;
                    &:before {
                        border-color: $radio-error-before !important;
                        background: $radio-error-before !important;
                    }
                }
            }
            p {
                font-family: GothamBook;
                font-size: $regular-font-size;
                letter-spacing: -0.8px;
            }
            input[type='checkbox'] {
                border: 0;
                clip: rect(1px, 1px, 1px, 1px);
                -webkit-clip-path: inset(50%);
                clip-path: inset(50%);
                height: 1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                white-space: nowrap;
                width: 1px;
                &:active + label,
                &:focus + label {
                    outline: none;
                }
                &:checked:active + label,
                &:checked:focus + label {
                    background-color: $input-valid;
                }
                &:checked + label {
                    background-color: $input-valid;
                    border: none;
                    &:hover {
                        background-color: $input-valid;
                        box-shadow: none;
                    }
                    &::before {
                        outline: none;
                        background-color: $input-valid-dark;
                        border-color: $input-valid-dark;
                        background-image: url('/img/svg-icons/white-tick.svg');
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: 60%;
                        border-radius: 10px;
                    }
                }
            }
            input[type='checkbox'] + label {
                box-shadow: 0 0 0 3px transparent;
                transition-duration: 0.2s;
                transition-property: box-shadow, background-color;
                transition-timing-function: ease-out;
                border-radius: $border-radius;
                color: #4c4c4c;
                cursor: pointer;
                display: -ms-inline-flexbox;
                display: inline-flex;
                flex-direction: column;
                -ms-flex-pack: center;
                justify-content: center;
                min-height: $input-field-height;
                padding: 14px 16px 0 16px;
                padding-left: 70px;
                position: relative;
                vertical-align: middle;
                font-family: EagleBook;
                font-size: $regular-font-size;
                letter-spacing: -0.5px;
                width: 100%;
                border: 1px solid $border-grey;
                left: 0;
                span {
                    line-height: 24px;
                }
                &:hover {
                    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
                }
                &::before {
                    background-color: #fff;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: 50% 50%;
                    border: 1px solid $border-grey;
                    content: ' ';
                    height: 30px;
                    width: 30px;
                    left: 20px;
                    position: absolute;
                    top: 14px;
                    transition-duration: 0.2s;
                    transition-property: background-color, border-color;
                    border-radius: 10px;
                }
            }
        }
    }
}
