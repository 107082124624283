/* Select */
.field-container.select {
    .accordion {
        flex-basis: 100%;
    }
    .react-select {
        min-width: 262px;
        display: inline-block;
        .error-message {
            flex-basis: 100%;
        }
        .success-tick {
            display: none;
        }
        &.is-invalid {
            .react-select__control {
                background: $input-invalid;
                border-color: $input-invalid-border;
            }
            .react-select__placeholder {
                color: $error-state;
            }
        }
        &.active {
            background: $input-valid;
            .react-select__control {
                background-color: transparent;
                border-color: $input-valid;
            }
            + .success-tick {
                display: inline-block;
            }
        }
        &__indicator {
            background-image: url($magnifyingGlassIcon);
            background-size: 62%;
            width: 30px;
            height: 30px;
            background-repeat: no-repeat;
            svg {
                display: none;
            }
            &-separator {
                display: none;
            }
        }
        &__placeholder {
            display: block !important;
        }
        &__control {
            cursor: pointer;

            &--menu-is-open {
                .react-select__placeholder {
                    color: red;
                }
            }
            .react-select__single-value {
                font-family: GothamBook;
                font-size: $regular-font-size;
                letter-spacing: -0.8px;
            }
        }
        & > div:first-of-type {
            height: $input-field-height;
            border-radius: $border-radius;
            box-shadow: 0 0 0 3px transparent;
        }
        &__value-container {
            height: $input-field-height;
            padding-top: 0 !important;
        }
        &__placeholder + div:first-of-type {
            height: $input-field-height !important;
            margin: 0;
            padding: 0;
            input {
                height: $input-field-height !important;
                margin: 0 !important;
                &:active,
                &:focus {
                    box-shadow: none !important;
                    border: none !important;
                }
            }
        }
        &__input,
        &__input input {
            margin-top: 0;
            padding-top: 0;
            height: $input-field-height !important;
            &:active,
            &:focus {
                box-shadow: none !important;
                border: none !important;
                outline: none;
            }
        }
    }
}
