/* Date input */
.field-container {
    &.date-container {
        .date-container__wrapper {
            width: 192px;
            border: 1px solid $border-grey;
            border-radius: $border-radius;
            height: $input-field-height;
            background: #fff;
            &.is-invalid {
                background: $input-invalid;
                border-color: $input-invalid-border;
                label {
                    color: $error-state;
                }
                input {
                    background: $input-invalid;
                    border-color: $input-invalid-border;
                }
            }
            &.active {
                input {
                    padding-top: 18px;
                }
                > label {
                    transform: translate(0, 2px) scale(0.7);
                    color: $inputPlaceholderFocus;
                    letter-spacing: -0.5px;
                }
                &.is-invalid > label {
                    color: $error-state;
                }
            }
            .date-input {
                border: none;
                height: 48px;
                &:last-of-type {
                    padding-left: $input-field-padding-small;
                }
                &:hover {
                    box-shadow: none;
                }
            }
            .mm-label {
                left: 88px;
                @include media-breakpoint-up(md) {
                    left: 98px;
                }
            }
            .yyyy-label {
                left: 144px;
                @include media-breakpoint-up(md) {
                    left: 154px;
                }
            }
            &.is-valid {
                background-color: $input-valid;
                border-color: $input-valid;
                box-shadow: none;
                input {
                    background-color: transparent;
                }
            }
        }
        legend {
            font-family: GothamBook;
            font-size: 12px;
            letter-spacing: -0.6px;
        }
    }
}
