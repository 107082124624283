/* Text inputs */
.field-container {
    .date-input,
    .sort-code-input {
        width: 50px;
    }
    display: flex;
    flex-wrap: wrap;
    position: relative;
    input[type='text'],
    input[type='number'],
    input[type='email'],
    input[type='password'],
    input[type='tel'],
    textarea,
    select {
        &:not(.date-input):not(.sort-code-input):not(textarea) {
            width: 100%;
            max-width: $input-max-width;
        }
        border: 1px solid $border-grey;
        border-radius: $border-radius;
        height: $input-field-height;
        padding-left: $input-field-padding;
        display: inline-block;
        font-size: $regularFont;
        letter-spacing: -0.8px;
        &:hover {
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
        }
        &:active,
        &:focus {
            border-color: $border-active;
        }
        &.is-valid {
            background-color: $white !important;
            border-color: $input-valid;
            box-shadow: none;
        }
        &.is-invalid {
            //background: $input-invalid;
            border-color: $input-invalid-border;
        }
        &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #ccc;
            opacity: 1; /* Firefox */
        }
    }

    &.is-invalid {
        label {
            color: $error-state;
        }
        &.active {
            > label {
                color: $error-state;
            }
        }
    }

    label:not(.radio__label):not(.label__pet-chooser) {
        position: relative;
        padding: 4px;
        background: $white;
        font-weight: 500;
        letter-spacing: -0.7px;
        width: 100%;
        margin-bottom: 0;
    }
    .error-message {
        @extend %body-copy-small;
        color: $error-state;
        position: absolute;
        bottom: -16px;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        box-shadow: 0 0 0 30px white inset !important;
    }
}
