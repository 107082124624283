.about-content {
    min-height: 70vh;
    color: $white;
    p {
        img.left {
            border-radius: $border-radius;
            float: left;
            @extend %margin-right;
            @extend %margin-bottom;
            width: 50%;
            @media (max-width: 768px) {
                width: 100%;
            }
        }
    }
}
