.sticky-menu-bar {
    @media (min-width: 768px) {
        display: none;
    }
    a:hover {
        text-decoration: none;
    }
    z-index: 10;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: $secondary-color;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    @extend %padding;
    &__icon {
        color: $white;
        text-align: center;
        &--title {
            font-size: 12px;
        }
    }
}
