.load-more-container {
    width: 100%;
    text-align: center;
    @extend %padding-top;
    button.load-more {
        @extend %load-more;
        margin: 0 auto;
        background: $primary-color;
        color: $white;
        &:hover {
            color: #f1f1f1;
            border-color: $primary-color !important;
        }
    }
}
