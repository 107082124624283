.gallery-videos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 0 !important;
    .video-container {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        @media (min-width: 769px) {
            flex-basis: 50%;
            padding-bottom: 28.25%;
        }
        flex-basis: 100%;
        @extend %margin-bottom;
        iframe {
            border-radius: $border-radius;
            z-index: 2;
            width: 98%;
            height: 100%;
            display: block;
            position: absolute;
            margin: 0;
            top: 0;
            left: 0;
        }
    }
}
