.double-block {
    &__story {
        display: inline-flex;
        justify-content: space-between;
        flex-wrap: wrap;
        background: $secondary-color;
        @extend %margin-bottom;
        @extend %margin-top;
        border-radius: $border-radius;
        overflow: hidden;
        &__image {
            flex-basis: 100%;
            @media (min-width: 576px) {
                flex-basis: 50%;
            }
        }

        &__copy {
            @extend %padding;
            flex-basis: 100%;
            display: flex;
            align-items: center;
            @media (min-width: 576px) {
                flex-basis: 44%;
            }
            color: $white;
            &-container {
                &__title,
                &__client,
                &__blurb {
                    @extend %margin-bottom;
                }
                &__title {
                    @extend %h2;
                }
                &__client {
                    @extend %strap;
                }
                &__blurb {
                    @extend %body-copy;
                }
                &__link {
                    @extend %link;
                    color: $white;
                    text-decoration: underline;
                }
            }
        }
    }
}
