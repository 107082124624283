$toasterWidth: 300px;
$toasterDesktopWidth: 590px;
$halfToasterWidth: $toasterDesktopWidth / 2;

.Toastify__toast-container {
    width: 100%;
    min-width: $toasterWidth;
    padding: 0 $padding-small * 2;

    @include media-breakpoint-down(md) {
        top: 1em;
        left: 0;

        &--top-center {
            margin-left: 0;
        }
    }

    @include media-breakpoint-up(md) {
        left: 50%;
        width: $toasterDesktopWidth;
        margin-left: -$halfToasterWidth;
        padding: 0;
    }

    .Toastify__toast {
        border-radius: $border-radius;
        display: flex;
        padding: $outer-padding;
        background-repeat: no-repeat;
        background-position: 20px 20px;

        @include media-breakpoint-down(md) {
            margin: 0 0 $padding-small * 2;
        }

        .toast-content {
            padding-left: 50px;

            &__title {
                font-family: $headerFont;
                margin-bottom: 0;
            }

            &__body {
                font-family: $normalFont;
            }
        }

        .Toastify__close-button {
            width: 20px;
            height: 20px;
            background: $white;
            opacity: 1;
            border-radius: 50%;
            color: $primaryGrey;
            margin-bottom: auto !important;
            margin-top: auto !important;
            font-size: $tiny-font-size;
        }

        &--default {
            background-color: $default;
            color: $primaryGrey;

            .Toastify__close-button {
                color: $primaryGrey;
            }
        }

        &--info {
            background-color: $info;
            background-image: $info-icon-path;

            .Toastify__close-button {
                color: $info;
            }
        }

        &--success {
            background-color: $success;
            background-image: $success-icon-path;

            .Toastify__close-button {
                color: $success;
            }
        }

        &--warning {
            background-color: $warning;
            background-image: $alert-icon-path;
            color: $primaryGrey;

            .Toastify__close-button {
                background-color: $primaryGrey;
                color: $warning;
            }
        }

        &--error {
            background-color: $error;
            background-image: $error-icon-path;

            .Toastify__close-button {
                color: $error;
            }
        }
    }
}
