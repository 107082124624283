header {
    background: $black;
    width: 100%;
    position: fixed;
    z-index: 100;
    top: 0;
    & + div {
        padding-top: $header-height;
    }
    @media (min-width: 768px) {
        position: relative;
        & + div {
            padding-top: 0;
        }
    }

    .header__wrapper {
        background: rgba(0, 0, 0, 0.05);
        .inner-content {
            display: flex;
            height: $header-height;
            @media (min-width: 768px) {
                justify-content: space-between;
            }
            justify-content: center;
            align-items: center;
            @extend %padding;
            ul,
            ul > li,
            .logo {
                display: inline-block;
            }
            .logo {
                margin: $padding-small 0;
                max-height: 60px;
            }
            ul {
                display: none;
                @media (min-width: 768px) {
                    display: inline-flex;
                    height: $header-height;
                }
                list-style: none;
                margin-bottom: 0;
                & > li {
                    @extend %margin-right;
                    a {
                        height: $header-height;
                        color: $white;
                        display: flex;
                        align-items: center;
                        &[aria-current='true'],
                        &:hover {
                            background-image: linear-gradient(
                                0deg,
                                white,
                                white
                            );
                            background-clip: content-box;
                            background-size: 1px 4px;
                            background-position: bottom;
                            background-repeat: repeat-x;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}
