.homepage-hero {
    background: $homepage-hero;
    background-repeat: no-repeat;
    @media (min-width: 900px) {
        background-size: contain;
    }
    background-position: center 90px;
    background-size: contain;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    background-attachment: fixed;
    height: 100vh;
    .homepage-hero__copy {
        margin-top: auto;
        background: rgba(0, 0, 0, 0.6);
        color: $white;
        &__container {
            display: flex;
            justify-content: space-between;
        }
    }
}
