.success-tick {
    margin-left: 8px;
    @extend %vertical-center;
    .field-container.is-search & {
        display: none;
    }
    @media all and (max-width: 400px) {
        display: none;
    }
    img {
        width: 30px;
    }
}
