.contact-details {
    &__contact {
        .inner-content {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .contact-details__contact__item {
                flex-basis: 100%;
                @extend %margin-bottom;
                a {
                    color: $white;
                    text-decoration: underline;
                }
                &--title {
                    @extend %h3;
                }
                &--content {
                    padding-top: $padding-small;
                    a,
                    p,
                    .address {
                        font-family: $normalFont;
                        font-size: $titleFont;
                        color: $primary-grey;
                    }
                }
            }
        }
    }
}
