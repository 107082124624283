$iconWidthHeight: 40px;
.social__outer {
    @extend %padding;
    text-align: center;
    color: $white;
    background: $tertiary-color;
    h4 {
        @extend %margin-bottom;
    }
    .social__container {
        display: inline-flex;
        padding-bottom: 0;
        @extend %padding;
        width: 260px;
        justify-content: space-between;
        button {
            width: $iconWidthHeight;
            height: $iconWidthHeight;
            svg {
                width: $iconWidthHeight !important;
                height: $iconWidthHeight !important;
            }
        }
    }
}
